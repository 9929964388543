@import 'styles/utils/sizes';
@import 'styles/utils/colors';
@import 'styles/utils/mixins';

.section {
  padding: 60px 16px 120px;

  .container {
    max-width: $wide-content-width;
    margin: 0 auto;
  }

  .card {
    @include flex(row, space-between, center);

    gap: 32px;
    padding: 64px;
    border-radius: 24px;
    border: 1px solid $gray0;
    background-color: $gray50;

    @include tablet {
      @include flex(column);
    }

    @include phone {
      padding: 40px 16px;
    }
  }

  .card-sidebar {
    @include flex(column, center, flex-start);

    flex: 0 0 100%;
    max-width: 417px;
    gap: 16px;

    @include tablet {
      max-width: 100%;
    }
  }

  .card-title {
    @include fontSize(38px, 120%);
    @include flex(row, initial, initial, wrap);

    margin: 0;
    padding: 0;
    gap: 8px;
    color: $gray900;
    font-weight: 600;

    @include phone {
      @include fontSize(20px, 130%);
    }
  }

  .card-description {
    @include fontSize(20px, 150%);

    margin: 0;
    padding: 0;
    color: rgba($gray800, 0.64);

    @include phone {
      @include fontSize(16px, 150%);
    }
  }

  .card-list {
    @include flex(row, flex-start, flex-start, wrap);

    column-gap: 8px;
    row-gap: 16px;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .list-item {
    padding: 8px 16px;
    border: 1px solid $gray900;
    border-radius: 999px;
    white-space: nowrap;

    &-1 {
      transform: rotate(0.85deg);
    }

    &-2 {
      transform: rotate(2.23deg);
    }

    &-3 {
      transform: rotate(1.94deg);
    }

    &-4 {
      transform: rotate(-4.41deg);
    }

    &-5 {
      transform: rotate(2.78deg);
    }

    &-6 {
      transform: rotate(2.63deg);
    }

    &-7 {
      transform: rotate(2.4deg);
    }

    &-8 {
      transform: rotate(1.37deg);
    }

    &-9 {
      transform: rotate(-2.1deg);
    }

    &-10 {
      transform: rotate(-1.32deg);
    }

    &-11 {
      transform: rotate(2.6deg);
    }

    &-12 {
      transform: rotate(2.21deg);
    }
  }

  .lastWord {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 4px;
      left: -4px;
      background-color: $yellow200;
      width: 80%;
      height: 8px;
      border-radius: 999px;
      z-index: -1;

      @include phone {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      right: -4px;
      background-color: $yellow200;
      width: 80%;
      height: 8px;
      border-radius: 999px;
      z-index: -1;

      @include phone {
        display: none;
      }
    }
  }

  @include phone {
    padding: 30px 16px 60px;
  }
}
